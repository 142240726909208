import React from 'react';
import Header from '../../components/common/global-top-bar';
import { VerticalBarChart } from '../../components/charts';
import PlayIcon from '../../assets/images/play-icon.svg';
import Kohli from '../../assets/images/kohli.svg';
import CalendarPaginator from '../../components/calender-paginator';

const Card = () => {
  return (
    <div className="w-full h-32 bg-gray-200 rounded grid grid-cols-12 px-7 my-2">
      <div className="col-span-3 flex items-center justify-center px-2">
        <div className="rounded-full h-24 w-24 bg-gray-100">
          <img className="h-full w-full " src={Kohli} alt="kohli" />
        </div>
      </div>
      <div className="col-span-6 py-4 px-4">
        <p className="text-2xl font-normal blue-primary  py-1">Kohli</p>
        <p className="text-base font-normal text-black  py-1">Indian captian</p>
        <p className="text-base font-normal text-black  py-1">3:28</p>
      </div>
      <div className="col-span-3 p-2 flex items-center justify-center">
        <div className="h-16 p-1 w-16">
          <img src={PlayIcon} alt="playicon" className="w-full h-full" />
        </div>
      </div>
    </div>
  );
};

const Stories: React.FunctionComponent = (): JSX.Element => {
  const data = [
    ['City', '2010 Population', { role: 'style' }, { role: 'annotation' }],
    ['New York City', 8175500, '', 8175500],
    ['New York City', 8175500, '', 8175500],
    ['New York Citym', 8174000, '', 8174000],
    ['Los Angeles', 3792300, '', 3792300],
    ['L A', 3792200, '', 3792200],
    ['Los Angels', 3791000, '', 3791000],
    ['Chicago', 2695900, '', 2695900],
    ['Chiccago', 2695400, '', 2695400],
    ['Chicago0', 2695100, '', 2695100],
    ['Houstoni', 2098000, '', 2098000],
    ['Housten', 2097000, '', 2097000],
    ['Houstren', 2097000, '', 2097000],
    ['Philadelphia', 1526000, '', 1526000],
    ['Philadelphib', 1525000, '', 1525000],
    ['Philadelphic', 1524000, '', 1524000],
  ];

  const options = {
    title: '',
    chartArea: { width: '50%', height: '90%' },
    colors: ['#1D1183'],
    legend: { position: 'none' },
    hAxis: {
      title: '',
      minValue: 0,
    },
    vAxis: {
      title: '',
    },
  };

  return (
    <div>
      <Header heading={'Stories'} />
      <CalendarPaginator />
      <div className="max-window-height pb-32 overflow-y-auto">
        <div className="flex justify-between px-7">
          <div>
            <div className="w-full px-7">
              <div className="relative  w-full  py-3 border-b gray-border-line text-xl font-bold text-gray-dark flex">
                <p>Top Stories</p>
                <p className="text-gray-light font-normal pl-2">(listen)</p>
              </div>
            </div>
            <VerticalBarChart data={data} options={options} />
          </div>
          <div>
            <div className="mt-12 mr-7">
              <Card />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stories;
