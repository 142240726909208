import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosPut } from '../api/axios';
import { ListenerRole, TagStatus } from './usePeers';

export interface TagToEntity {
  created_at: string;
  id: number;
  status: TagStatus;
  listener_audio_id: any;
  media: any;
  postcall_metadata_id: any;
  sort_order: number;
  tag: Tag;
  tag_id: number;
  user_id: number;
}

export interface Tag {
  id: number;
  is_background: boolean;
  is_default: boolean;
  is_required: boolean;
  is_visible: boolean;
  media: any;
  name: string;
  sort_order: number;
  tag_type: string;
  hasTag: boolean;
}

const updateTagRequest = async (listenerId: number, tagToEntityId: number, data: any) => {
  return await axiosPut(`/listeners/${listenerId}/listener_tags/${tagToEntityId}`, data, 'v2').then(
    (response: { data: any }) => response.data,
  );
};

export const useUpdateTag = (listenerId: number, tagToEntityId: number) => {
  const queryClient = useQueryClient();
  const updateTag = useMutation((data: any) => updateTagRequest(listenerId, tagToEntityId, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['listener', listenerId]);
    },
    onError: () => {
      queryClient.invalidateQueries(['listener', listenerId]);
    },
    onMutate: (data) => {
      queryClient.setQueryData(['listener', listenerId], (oldData?: ListenerRole) => {
        if (oldData) {
          const updatedBackgroundTags = oldData.background_tags?.map((backgroundTag) => {
            if (backgroundTag?.id === tagToEntityId) {
              return { ...backgroundTag, ...data };
            }
            return backgroundTag;
          });
          return { ...oldData, background_tags: updatedBackgroundTags };
        }
        return oldData;
      });
    },
  });
  return {
    updateTag,
  };
};
