import React, { useState } from 'react';
import Header from '../../components/common/global-top-bar';
import RightArrow from '../../assets/images/left-arrow-symbol.svg';
import { useHistory } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import Spinner from '../../components/common/spinner';
import { ROUTE_PATH } from '../../routes/paths';
import Searchbar from '../../components/common/searchbar';
import {
  fetchBlackListListRequest,
  fetchBlackListCountRequest,
  fetchBlockedCountRequest,
  fetchBlockedListRequest,
  removeBlockedRequest,
} from './redux-tags/actions';
import { Pagination } from '../../components/common';
import { Headeroptions } from './user-safety-constants';
import SwitchBar from '../../components/switch-bar';
import AlertBox from '../../components/route-leaving-prompt/alert-box';

export const blackListType = {
  'Phone Number': 'mobile_phone',
  'Email Address': 'email_address',
  'IP Address': 'ip_address',
};

type state = {
  showModal: boolean;
  resourceId: number;
  name: string;
};

type FilterProps = {
  addFunctionality: () => void;
  fetchData: (data: any) => void;
  count: number | undefined;
};

const Filters: React.FunctionComponent<FilterProps> = ({ count, fetchData }): JSX.Element => {
  const [filters, setfilters] = useState({
    page: 1,
    limit: 25,
    entity_type: blackListType['Phone Number'],
  });

  return (
    <div className="w-full border-b gray-border-line flex justify-between items-center bg-gray-background-light px-7  h-10 ">
      <div className="w-1/3">
        <div className="w-full h-8 flex">
          <div className="w-3/4">
            <Searchbar
              search={(data) => {
                setfilters((prev) => {
                  return { ...prev, page: 1, search: data };
                });
                fetchData({ ...filters, page: 1, search: data });
              }}
            />
          </div>
        </div>
      </div>
      <div className="w-1/4 ">
        <Pagination
          pageCount={Math.ceil((count || 10) / 25)}
          key={Math.ceil((count || 10) / 25)}
          updatePageNumber={(pages) => {
            setfilters((filter) => {
              return { ...filter, page: pages + 1 };
            });
            fetchData({ ...filters, page: pages + 1 });
          }}
        />
      </div>
      <div className="w-1/3"></div>
    </div>
  );
};

const defaultState = {
  showModal: false,
  resourceId: 0,
  name: '',
};

type CallerBlockListProps = {
  blackList: any;
  pending: any;
  blackListCount: any;
};

const CallerBlockList: React.FunctionComponent<CallerBlockListProps> = (props): JSX.Element => {
  const dispatch = useDispatch();
  const [filters, setfilters] = useState();
  const [state, setState] = useState<state>(defaultState);
  const fetchData = (filter: any) => {
    const { page, limit, ...rest } = filter;
    setfilters(filter);
    dispatch(fetchBlockedListRequest(filter));
    dispatch(fetchBlockedCountRequest(rest));
  };

  const deleteBlackList = () => {
    dispatch(removeBlockedRequest({ id: state.resourceId, filters }));
    setState(defaultState);
  };

  return (
    <div>
      <Header heading={'Safety'} />
      <SwitchBar heading={Headeroptions} position={2} />

      <Filters count={props.blackListCount?.count} fetchData={fetchData} addFunctionality={() => {}} />

      <div className="max-window-height pb-10 overflow-y-auto ">
        <div className="w-full px-7">
          {props.pending && <Spinner />}
          <div className="w-full  border-b-2 border-black font-bold text-gray-dark text-left h-12   grid grid-cols-12  items-center sticky mb-2 ">
            <div className=" font-bold col-span-5">
              {/* @ts-ignore */}
              <p className="pl-10">caller and listener</p>
            </div>
          </div>
          <div className="w-full  overflow-y-scroll">
            {props.blackList?.map((tag: any) => (
              <div className="w-full bg-gray-200 rounded-xl text-gray-dark text-left h-12 border-y my-2 grid grid-cols-12  items-center cursor-pointer">
                <div className=" font-bold col-span-5">
                  <p className="pl-10">{`${tag.from_user.display_name} <---> ${tag.to_user.display_name}`}</p>
                </div>
                <div className=" col-span-7 flex justify-between mr-2 items-center">
                  <div className=" col-span-3 capitalize"> {tag.entity_type} </div>

                  <button
                    className="rounded-md px-2 py-1 font-bold bg-blue-primary  text-white"
                    onClick={() => {
                      setState({
                        showModal: true,
                        name: `${tag.from_user.display_name} <---> ${tag.to_user.display_name}`,
                        resourceId: tag.id,
                      });
                    }}
                  >
                    DELETE
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <AlertBox
        visible={state.showModal}
        handlePrompt={deleteBlackList}
        closeModal={() => setState(defaultState)}
        titleText={'Alert'}
        contentText={`Are you sure you want to remove connection block ${state.name}?`}
        cancelButtonText={'Cancel'}
        confirmButtonText={'Remove'}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    blackList: state.safety.BlockedList?.data || null,
    blackListCount: state.safety.BlockedList?.data || null,
    pending: state.safety?.pending || null,
  };
};

export default connect(mapStateToProps)(CallerBlockList);
