import React, { useEffect, useState, useCallback } from 'react';
import Header from '../../components/common/global-top-bar';
import RejectMessageModal from './common/reject-message-modal';
import ReviewPageControls, { FilterOptionsType, FilterType } from './common/review-page-controls';
import { connect, useDispatch } from 'react-redux';
import {
  fetchListenerCountRequest,
  fetchListenersRequest,
  updateListenerDetailsRequest,
} from './listeners-redux/actions';
import { RejectModalDataType, ListenerDataType, CountResponseType } from './common/types';
import Spinner from '../../components/common/spinner';
import { DropDown } from '../../components/common';
import SwitchBar from '../../components/switch-bar';
import { HeaderOptions } from './common/listener-review-constants';
import { useHistory } from 'react-router';

const displayNames: FilterOptionsType = {
  pending: 'Needs Approval',
  approved: 'Approved',
  rejected: 'Rejected',
};

type HandleSaveType = (userId: number, displayNameStatus: string) => void;

interface TableRowProps {
  displayName: string;
  proposedDisplayName: string;
  displayNameRejected: boolean;
  firstName: string;
  lastName: string;
  emailAddress: string;
  userId: number;
  listenerId: number;
  handleSave: HandleSaveType;
}

const TableRow: React.FunctionComponent<TableRowProps> = ({
  displayName,
  proposedDisplayName,
  displayNameRejected,
  firstName,
  lastName,
  emailAddress,
  userId,
  listenerId,
  handleSave,
}): JSX.Element => {
  const history = useHistory();

  const evaluateDisplayNameState = (): string => {
    if (displayNameRejected) {
      return 'rejected';
    } else if (proposedDisplayName || !displayName) {
      return 'pending';
    } else {
      return 'approved';
    }
  };

  const [displayNameApprovalStatus, setDisplayNameApprovalStatus] = useState<string>();

  useState(() => {
    if (userId && !displayNameApprovalStatus) {
      setDisplayNameApprovalStatus(evaluateDisplayNameState());
    }
  });

  return (
    <tr className="text-left w-full px-7">
      <td></td>
      <td>
        <div>{proposedDisplayName || <i>No proposed display name</i>}</div>
        <div>{displayName || <i>No approved display name</i>}</div>
      </td>
      <td>
        <div className="flex">
          <div>
            <DropDown
              Label=""
              value={displayNameApprovalStatus}
              setValue={(data) => {
                setDisplayNameApprovalStatus(data);
              }}
            >
              {Object.keys(displayNames).map((value: string) => (
                <option key={value} value={value}>
                  {displayNames[value]}
                </option>
              ))}
            </DropDown>
          </div>
          <div className="pl-5">
            <button
              type="button"
              className="bg-bright-blue rounded-lg py-1 px-3 text-white w-auto"
              onClick={() => {
                if (displayNameApprovalStatus) handleSave(userId, displayNameApprovalStatus);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </td>
      <td>{firstName}</td>
      <td>{lastName}</td>
      <td>{emailAddress}</td>
      <td>
        <button
          onClick={() => {
            history.push(`/listeners/profile/?listenerRoleId=${listenerId}&listenerId=${userId}`);
          }}
        >
          {userId}
        </button>
      </td>
    </tr>
  );
};

type DisplayNameReviewProps = {
  listeners: ListenerDataType[];
  listenersCount: CountResponseType;
  pending: boolean;
};

type DisplayNameFilterType = {
  page: number;
  limit: number;
  search: string;
  display_name_status: string | null;
  hide_incomplete_peers: boolean;
};
const displayNameFilterOptions = {
  ...displayNames,
  all: 'All',
};

const DisplayNameReview: React.FunctionComponent<DisplayNameReviewProps> = (props): JSX.Element => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState<DisplayNameFilterType>({
    page: 1,
    limit: 25,
    search: '',
    display_name_status: 'pending',
    hide_incomplete_peers: true,
  });
  const [messagePopUp, setMessagePopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState<number | null>();

  const updateFilter = useCallback(
    (filter: FilterType) => {
      setFilters((prev: DisplayNameFilterType) => {
        return {
          page: filter.page,
          limit: filter.limit,
          search: filter.search,
          display_name_status: filter.filterValue !== 'all' ? filter.filterValue : null,
          hide_incomplete_peers: true,
        };
      });
    },
    [setFilters],
  );

  const fetchData = useCallback(
    (requestPayload: DisplayNameFilterType) => {
      dispatch(fetchListenersRequest(requestPayload));
      dispatch(fetchListenerCountRequest(requestPayload));
    },
    [dispatch],
  );

  useEffect(() => {
    if (filters) {
      fetchData(filters);
    }
  }, [fetchData, filters]);

  const getSelectedUserData = (userId: number) => {
    return props.listeners?.find((listener: any) => {
      return listener.user.id === userId;
    });
  };

  const handleSaveRejected = (rejectModalData: RejectModalDataType) => {
    const rejectedMessagePayload = rejectModalData.send_message ? rejectModalData : {};
    const payload = {
      display_name_rejected: true,
      ...rejectedMessagePayload,
    };
    dispatch(updateListenerDetailsRequest({ id: selectedUser, payload }));
    handleRejectModalCancel();
    if (filters) {
      fetchData(filters);
    }
  };

  const handleSaveApprove = (userId: number) => {
    const selectedUserData = getSelectedUserData(userId);
    const payload = {
      display_name: selectedUserData?.user.proposed_display_name || '',
    };
    dispatch(updateListenerDetailsRequest({ id: userId, payload }));
    if (filters) {
      fetchData(filters);
    }
  };

  const handleSavePending = (userId: number) => {
    const selectedUserData = getSelectedUserData(userId);
    const payload = {
      proposed_display_name: selectedUserData?.user.display_name || '',
    };
    dispatch(updateListenerDetailsRequest({ id: userId, payload }));
    if (filters) {
      fetchData(filters);
    }
  };

  const handleSave: HandleSaveType = (userId, displayNameStatus) => {
    switch (displayNameStatus) {
      case 'approved':
        handleSaveApprove(userId);
        break;
      case 'pending':
        handleSavePending(userId);
        break;
      case 'rejected':
        setSelectedUser(userId);
        setMessagePopup(true);
        break;
    }
  };

  const handleRejectModalCancel = () => {
    setSelectedUser(null);
    setMessagePopup(false);
  };

  return (
    <div>
      {props.pending && <Spinner />}
      <Header heading={'Display Name'} />
      <SwitchBar heading={HeaderOptions} position={3} />
      <ReviewPageControls
        updateFilter={updateFilter}
        count={props.listenersCount?.count}
        filterOptions={displayNameFilterOptions}
        defaultFilterValue="pending"
      />

      <div className="max-window-height overflow-y-auto px-7">
        <div className="table-overflow">
          <table className="table-fixed table-row-format w-full relative border-collapse">
            <thead className="bg-white h-full">
              <tr className="text-left w-full px-7">
                <th className="sticky top-0 bg-white h-full mb-4 text-left w-8"></th>
                <th className="py-2 sticky top-0 bg-white h-full mb-4 w-64">Display Name</th>
                <th className="sticky top-0 bg-white h-full mb-4 text-left w-80">Action</th>

                <th className="sticky top-0 bg-white h-full mb-4 w-44">First Name</th>
                <th className="sticky top-0 bg-white h-full mb-4 w-44">Last Name</th>
                <th className="sticky top-0 bg-white h-full mb-4 w-80">Email Address</th>
                <th className="sticky top-0 bg-white h-full mb-4 w-15">Peer ID</th>
              </tr>
            </thead>
            <tbody>
              <tr className="h-2"></tr>
              {props.listeners?.length > 0 &&
                props.listeners.map((listenerData: any) => (
                  <TableRow
                    key={listenerData?.id}
                    displayName={listenerData?.user?.display_name || ''}
                    proposedDisplayName={listenerData?.user?.proposed_display_name || ''}
                    displayNameRejected={listenerData?.user?.display_name_rejected || ''}
                    lastName={listenerData?.user?.last_name || ''}
                    firstName={listenerData?.user?.first_name || ''}
                    emailAddress={listenerData?.user.email_address}
                    userId={listenerData?.user.id}
                    listenerId={listenerData?.id}
                    handleSave={handleSave}
                  />
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <RejectMessageModal
        systemMessageGroupKey="display_name_rejected"
        messagePopup={messagePopUp}
        handleModalCancel={handleRejectModalCancel}
        handleModalSave={handleSaveRejected}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    listeners: state.Listeners?.listeners?.data || null,
    listenersCount: state.Listeners?.listenersCount?.data || null,
    pending: state.Listeners?.pending || false,
  };
};

export default connect(mapStateToProps)(DisplayNameReview);
