import { useQuery } from '@tanstack/react-query';
import { axiosGet } from '../api/axios';

export interface SystemMessage {
  archived_at: any;
  client_blacklist: any;
  delay_while_on_call: boolean;
  description: string;
  email_template: any;
  group: Group;
  group_id: number;
  id: number;
  key: string;
  message: string;
  name: string;
  no_group_logo: boolean;
  send_app_message: boolean;
  send_email: boolean;
  send_notification: boolean;
  send_sms: boolean;
  send_sms_as_primary: boolean;
  sms_channel_id: any;
  sms_data: any;
  sms_media: any;
  subject: string;
}

export interface Group {
  description: string;
  id: number;
  key: string;
  name: string;
}

const getSystemMessages = async (): Promise<SystemMessage[]> => {
  return await axiosGet('/comms/system_messages', {}, 'v2').then((commsResponse) => commsResponse.data);
};

export const useSystemMessages = () => {
  const { data, isLoading, error } = useQuery<SystemMessage[]>(['system_messages'], getSystemMessages);

  return { data, isLoading, error };
};
