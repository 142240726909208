import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { axiosGet, axiosPut } from '../api/axios';
import { toast } from 'react-toastify';

export interface SettingsResponse {
  message: {
    KH_NUMBER_DOESNT_TAKE_CALLS_VOICE: string;
    LOC_CALLER_BEGIN_TEXT: string;
    LOC_CALLER_CONNECTIONS_AVAILABLE: string;
    LOC_CALLER_DISCONNECTED_VOICE: string;
    LOC_CALLER_EMAIL_BANNED: string;
    LOC_CALLER_EMAIL_SUSPENDED: string;
    LOC_CALLER_EMAIL_UNDER_REVIEW: string;
    LOC_CALLER_MESSAGE_BANNED: string;
    LOC_CALLER_MESSAGE_BANNED_SUBJECT: string;
    LOC_CALLER_MESSAGE_RESTORED: string;
    LOC_CALLER_MESSAGE_RESTORED_SUBJECT: string;
    LOC_CALLER_MESSAGE_SUSPENDED: string;
    LOC_CALLER_MESSAGE_SUSPENDED_SUBJECT: string;
    LOC_CALLER_MESSAGE_UNDER_REVIEW: string;
    LOC_CALLER_MESSAGE_UNDER_REVIEW_SUBJECT: string;
    LOC_CALLER_VOICEMAIL_DETECTED_VOICE: string;
    LOC_CONNECT_NOW_TEXT: string;
    LOC_LISTENER_BEGIN_TEXT: string;
    LOC_LISTENER_CONNECTION_REQUEST: string;
    LOC_LISTENER_COULD_NOT_REACH_TEXT: string;
    LOC_LISTENER_EMAIL_BANNED: string;
    LOC_LISTENER_EMAIL_SUSPENDED: string;
    LOC_LISTENER_EMAIL_UNDER_REVIEW: string;
    LOC_LISTENER_FAIL_TO_START_TEXT: string;
    LOC_LISTENER_IVR_MENU: string;
    LOC_LISTENER_MESSAGE_BANNED: string;
    LOC_LISTENER_MESSAGE_BANNED_SUBJECT: string;
    LOC_LISTENER_MESSAGE_GOING_OFFLINE: string;
    LOC_LISTENER_MESSAGE_GOING_ONLINE: string;
    LOC_LISTENER_MESSAGE_PROFILE_REJECTED: string;
    LOC_LISTENER_MESSAGE_PROFILE_REJECTED_SUBJECT: string;
    LOC_LISTENER_MESSAGE_RESTORED: string;
    LOC_LISTENER_MESSAGE_RESTORED_SUBJECT: string;
    LOC_LISTENER_MESSAGE_SUSPENDED: string;
    LOC_LISTENER_MESSAGE_SUSPENDED_SUBJECT: string;
    LOC_LISTENER_MESSAGE_UNDER_REVIEW: string;
    LOC_LISTENER_MESSAGE_UNDER_REVIEW_SUBJECT: string;
    LOC_LISTENER_RECEIPT_ICAL_BODY: string;
    LOC_LISTENER_TOPIC_NOTIFY_LACKING_BODY: string;
    LOC_LISTENER_TOPIC_NOTIFY_LACKING_EMAIL_BODY: string;
    LOC_LISTENER_TOPIC_NOTIFY_LACKING_SUBJECT: string;
    LOC_LISTENER_TOPIC_NOTIFY_POOR_BODY: string;
    LOC_LISTENER_TOPIC_NOTIFY_POOR_EMAIL_BODY: string;
    LOC_LISTENER_TOPIC_NOTIFY_POOR_SUBJECT: string;
    LOC_LISTENER_TOPIC_REJECT_CONTENTS_BODY: string;
    LOC_LISTENER_TOPIC_REJECT_CONTENTS_EMAIL_BODY: string;
    LOC_LISTENER_TOPIC_REJECT_CONTENTS_SUBJECT: string;
    LOC_LISTENER_TOPIC_REJECT_POOR_BODY: string;
    LOC_LISTENER_TOPIC_REJECT_POOR_EMAIL_BODY: string;
    LOC_LISTENER_TOPIC_REJECT_POOR_SUBJECT: string;
    LOC_LISTENER_TOPIC_REJECT_SHORT_BODY: string;
    LOC_LISTENER_TOPIC_REJECT_SHORT_EMAIL_BODY: string;
    LOC_LISTENER_TOPIC_REJECT_SHORT_SUBJECT: string;
    LOC_LISTENER_VOICEMAIL_DETECTED_TEXT: string;
    LOC_LISTENER_WELCOME: string;
    LOC_MEMBER_RECEIPT_ICAL_BODY: string;
    LOC_MESSAGE_RECEIVED: string;
    LOC_MOBILE_VERIFICATION: string;
    LOC_NO_ACTIVE_CALL: string;
    LOC_TONE_SOUND_URL: string;
    LOC_USER_MESSAGE_UNDER_REVIEW: string;
    LOC_USER_MESSAGE_UNDER_REVIEW_SUBJECT: string;
    LOC_USER_MESSAGE_UNSUSPEND: string;
    LOC_USER_MESSAGE_UNSUSPEND_SUBJECT: string;
  };
  system: {
    ABOUT_LOC: string;
    ABOUT_US: string;
    CLIENT_ADMIN_TERMS_OF_SERVICE: string;
    FEATURED_LISTENER_TOPIC: string;
    LISTENER_FAQ: string;
    LOC_CALLER_CALL_START_DEADMAN_TIMER: number;
    LOC_CALLER_MOOD_COLLECTION_REMINDER_TIMER: number;
    LOC_CALLER_VOICEMAIL_DETECTED_TIMEOUT: number;
    LOC_CALL_CENTS_PER_MINUTE: number;
    LOC_CALL_HOLD_OPEN_TIMEOUT: number;
    LOC_CALL_LENGTH_SETTLE_THRESHOLD: number;
    LOC_DEADMAN_TIMER: number;
    LOC_DEFAULT_CALL_DURATION: number;
    LOC_EXPERIENCE_PAYOUT_THRESHOLD: number;
    LOC_FORCE_WEB_APP: boolean;
    LOC_LISTENER_BACKGROUND_CHECK_FEE: number;
    LOC_LISTENER_BACKGROUND_CHECK_REWARD: number;
    LOC_LISTENER_CALL_START_DEADMAN_TIMER: number;
    LOC_LISTENER_CALL_START_IVR_COUNT: number;
    LOC_LISTENER_CALL_START_IVR_TIMEOUT: number;
    LOC_LISTENER_DISCOVERY_MAX_DISPLAY: number;
    LOC_LISTENER_INITIAL_CALLOUT_DELAY: number;
    LOC_LISTENER_REWARD_CALL_REQUIREMENT: number;
    LOC_LISTENER_ROLE_MAXIMUM_COUNT: number;
    LOC_LISTENER_VOICEMAIL_DETECTED_DEADMAN_TIMER: number;
    LOC_MINIMUM_LISTENER_EXPERIENCES: number;
    LOC_MINIMUM_PEER_EXPERIENCES: number;
    LOC_UNLIMITED_CREDIT_RESERVE_AMOUNT: number;
    LOC_UNLIMITED_MINUTES_RESERVE_AMOUNT: number;
    LOC_WEB_APP_URL: string;
    MACHINE_DETECTION_SILENCE_TIMEOUT: number;
    MACHINE_DETECTION_SPEECH_END_THRESHOLD: number;
    MACHINE_DETECTION_SPEECH_THRESHOLD: number;
    MACHINE_DETECTION_TIMEOUT: number;
    PEER_FAQ: string;
    PRIVACY_POLICY: string;
    REFLECTIONS_SPONSOR: number;
    TERMS_OF_SERVICE: string;
    TRUSTED_SESSION_TTL: number;
    UNTRUSTED_SESSION_TTL: number;
    WELCOME_VIDEO: string;
  };
}

export const useSystemSettings = (type: string) => {
  return useQuery<any>(['settings', 'system', type], () => {
    return axiosGet(`/settings/system/${type}`, {}).then((res) => res.data);
  });
};

export const useSettings = (type?: 'email' | null) =>
  useQuery<SettingsResponse>(['settings', type], () => {
    return axiosGet(`/settings${type === 'email' ? '/' : '/app'}`, {}).then((res) => res.data.data);
  });

export const useUpdateSettings = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (input: any) => {
      return axiosPut('/settings', input);
    },
    onSuccess: () => {
      toast.success('Settings updated');
    },
    onError: (e: any) => {
      toast.error('Something went wrong');
    },
    onSettled: () => queryClient.invalidateQueries(['settings']),
  });
};

export const useUpdateSystemSettings = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ setting, input }: { setting: string; input: any }) => {
      return axiosPut(`/settings/system/${setting}`, input);
    },
    onSuccess: () => {
      toast.success('System settings updated');
    },
    onError: (e: any) => {
      toast.error('Something went wrong');
    },
    onSettled: () => queryClient.invalidateQueries(['settings', 'system']),
  });
};
