import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { CustomInput, UseFormDropDown } from '../../../components/common';
import { onBlur } from '../../../components/common/useForm';
import AlertBox from '../../../components/route-leaving-prompt/alert-box';
import { useCreateExperienceMutation, CreateExperienceRequest } from '../../../hooks/useListenerAudio';
import { Tag, TagExtended, useTags } from '../../../hooks/useTags';
import { Dialog } from '@kindlyhuman/component-library';
import Modal from '../../../components/common/modal';

interface videoExperiencePayload {
  admin_summary: string;
  excerpt: string;
  video_id: string;
  message_quality: string;
  title: string;
  topic_tag_ids: number[];
}

interface CreateVideoModalProps {
  listenerId: number;
  setCreateVideoModal: (value: boolean) => void;
  visible: boolean;
}

export const CreateVideoModal: React.FunctionComponent<CreateVideoModalProps> = ({
  listenerId,
  setCreateVideoModal,
  visible,
}) => {
  const { mutate: createExperience } = useCreateExperienceMutation();
  const { data: initialTopicTags } = useTags({ tag_type: 'topic', include_hidden: true });
  const [topicTags, setTopicTags] = useState<TagExtended[]>([]);
  const [alertModal, setAlertModal] = useState<boolean>(false);
  const [tagsModalOpen, setTagsModalOpen] = useState(false);

  const defaultExperience: videoExperiencePayload = {
    admin_summary: '',
    excerpt: '',
    video_id: '',
    message_quality: '7',
    title: '',
    topic_tag_ids: [],
  };

  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: defaultExperience,
  });

  const toggleHasTag = (hasTag: boolean, id: number) => {
    const updatedTags = topicTags.map((item: TagExtended) => {
      if (item.id === id) {
        return { ...item, hasTag };
      } else {
        return item;
      }
    });

    setTopicTags(updatedTags);

    const updatedTagIds = updatedTags.filter((tag) => tag.hasTag).map((tag) => tag.id);
    setValue('topic_tag_ids', updatedTagIds, { shouldDirty: true });
  };

  const onSubmit = (payload: CreateExperienceRequest) => {
    createExperience({ listenerId, payload });
    setCreateVideoModal(false);
    reset();
  };

  useEffect(() => {
    if (initialTopicTags) {
      const processedTags = initialTopicTags.map((tag: Tag) => {
        return { ...tag, hasTag: false };
      });
      setTopicTags(processedTags);
    }
  }, [initialTopicTags]);

  return (
    <>
      <Modal
        isModel={visible}
        InnerComponent={
          <div
            className="inline-block align-bottom bg-white border overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)} id="create-video-experience-form">
              <div className="bg-white px-2 pt-3 pb-2 sm:p-6 sm:pb-4">
                <div className="flex justify-start ">
                  <div>
                    <h3 className="text-2xl font-normal blue-primary py-1">Create Video Experience</h3>
                  </div>
                </div>
                <div className="py-2 flex flex-start">
                  <h3 className="text-lg font-bold text-gray-dark py-1">Details</h3>
                </div>
                <div className="grid grid-cols-2 gap-3 gap-y-5 mr-5">
                  <div>
                    <CustomInput
                      data-testid="vimeo_id"
                      inputType="text"
                      Label="Vimeo ID"
                      register={{
                        ...register('video_id', {
                          required: 'Please provide a Vimeo ID',
                          onBlur: (e: any) => onBlur(e, 'video_id', setValue),
                        }),
                      }}
                      Error={!!errors?.video_id?.message?.length}
                      ErrorMessage={errors.video_id?.message}
                    />
                  </div>
                  <div>
                    <CustomInput
                      data-testid="title"
                      inputType="text"
                      Label="Title"
                      register={{
                        ...register('title', {
                          onBlur: (e: any) => onBlur(e, 'title', setValue),
                        }),
                      }}
                      Error={!!errors?.title?.message?.length}
                      ErrorMessage={errors.title?.message}
                    />
                  </div>
                  <div>
                    <h3 className="block text-left leading-4 text-gray-dark my-2">Excerpt</h3>
                    <div className="w-full my-2">
                      <textarea
                        data-testid="excerpt"
                        className="py-2 px-3 w-full rounded-md h-36 text-gray-dark bg-gray-background-light text-sm font-semi-bold leading-4 focus:outline-none "
                        {...register('excerpt', {
                          onBlur: (e: any) => onBlur(e, 'excerpt', setValue),
                        })}
                      />
                      <div className="text-right text-sm text-gray-dark">{watch('excerpt')?.length} characters</div>

                      {!!errors?.excerpt?.message?.length && (
                        <label className="block text-xs leading-4  font-medium text-red mb-2 pt-2">
                          {errors.excerpt?.message}
                        </label>
                      )}
                    </div>
                  </div>
                  <div>
                    <h3 className="block text-left leading-4 text-gray-dark my-2">Admin Summary</h3>
                    <div className="w-full my-2">
                      <textarea
                        data-testid="admin_summary"
                        className="py-2 px-3 w-full rounded-md h-36 text-gray-dark bg-gray-background-light text-sm font-semi-bold leading-4 focus:outline-none "
                        {...register('admin_summary', {
                          onBlur: (e: any) => onBlur(e, 'admin_summary', setValue),
                        })}
                      />
                      <div className="text-right text-sm text-gray-dark">
                        {watch('admin_summary')?.length} characters
                      </div>

                      {!!errors?.admin_summary?.message?.length && (
                        <label className="block text-xs leading-4  font-medium text-red mb-2 pt-2">
                          {errors.admin_summary?.message}
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="text-left">
                    <UseFormDropDown label={'Grade'}>
                      <select className="select-box w-full" defaultValue="7" {...register('message_quality')}>
                        <option value="7">Ungraded</option>
                        <option value="3">Approved</option>
                        <option value="10">Rejected</option>
                      </select>
                    </UseFormDropDown>
                  </div>
                  <div></div>
                  <div className="flex flex-col col-span-2">
                    <div className="flex space-x-4 items-center">
                      <p className="py-2 leading-4 text-gray-dark text-left">Topic Tags</p>
                      <button
                        type="button"
                        className="py-2 font-semibold text-right text-gray-dark"
                        onClick={() => {
                          setTagsModalOpen(true);
                        }}
                      >
                        + Add
                      </button>
                    </div>
                    <div className="w-full flex-wrap pb-4 space-x-2 space-y-2 text-sm -ml-2 ">
                      {topicTags?.length > 0 &&
                        topicTags
                          .filter((tag: TagExtended) => tag.hasTag && !tag.is_required)
                          .map((tag: TagExtended, index: number) => {
                            return (
                              <button
                                className={`bg-blue-primary text-white rounded-md text-sm font-bold ${
                                  index === 0 ? 'ml-2 mt-2' : ''
                                }`}
                              >
                                <div className="flex px-3 py-2">
                                  <div className="grow  pr-2">{tag.name}</div>
                                  <button
                                    type="button"
                                    className="grow-0 "
                                    onClick={() => {
                                      toggleHasTag(false, tag.id);
                                    }}
                                  >
                                    x
                                  </button>
                                </div>
                              </button>
                            );
                          })}
                    </div>
                  </div>
                </div>
                <div className="flex justify-end mt-2">
                  <button
                    className={
                      'text-center h-8 text-sm text-white px-2 w-16 rounded-sm ' +
                      (isDirty ? 'bg-blue-primary' : 'bg-gray-200')
                    }
                    type="submit"
                    form="create-video-experience-form"
                    disabled={!isDirty}
                  >
                    Save
                  </button>
                  <button
                    className="text-center h-8 text-sm text-white px-2 bg-blue-primary w-16 rounded-sm ml-4 "
                    type="button"
                    onClick={() => {
                      if (isDirty) {
                        setAlertModal(true);
                      } else {
                        setCreateVideoModal(false);
                      }
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        }
      />
      {/* TAGS MODAL */}
      <Dialog open={tagsModalOpen} onClose={() => setTagsModalOpen(false)}>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all ">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6  text-gray-900 font-bold mb-3" id="modal-title">
                  Add Listener Tags
                </h3>
                <div className="w-full pb-4 flex-wrap space-x-2 space-y-2 text-sm -ml-2 ">
                  {topicTags?.length > 0 &&
                    topicTags
                      .filter((tag: TagExtended) => !tag.is_required)
                      .sort((tag1: TagExtended, tag2: TagExtended) => tag1.name.localeCompare(tag2.name))
                      .map((tag: TagExtended, index: number) => {
                        return (
                          <button
                            className={` ${
                              tag.hasTag ? ' bg-blue-primary  text-white' : ' bg-gray-background-light '
                            }  rounded-md text-sm font-bold ${index === 0 ? ' ml-2 mt-2 ' : ' '}`}
                            onClick={() => {
                              toggleHasTag(!tag.hasTag, tag.id);
                            }}
                          >
                            <div className="flex px-3 py-2">
                              <div className="grow  pr-2">{tag.name}</div>
                            </div>
                          </button>
                        );
                      })}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:justify-center">
            <button
              type="button"
              className={'px-5 py-1 text-white rounded-full font-medium bg-gray-400 '}
              onClick={() => {
                setTagsModalOpen(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>
      {/* UNSAVED CHANGES MODAL */}
      <AlertBox
        visible={alertModal}
        handlePrompt={() => {
          reset();
          setCreateVideoModal(false);
          setAlertModal(false);
        }}
        closeModal={() => {
          setAlertModal(false);
        }}
        confirmButtonText={'Discard Changes'}
        titleText={'Alert'}
        contentText={'You have unsaved changes. If you leave this screen without saving, your changes will be lost.'}
        cancelButtonText={'Cancel'}
      />
    </>
  );
};
