import React, { useEffect, useState } from 'react';
import { Spinner } from '../../../components/common';
import Header from '../../../components/common/global-top-bar';
import SwitchBar from '../../../components/switch-bar';
import { Headeroptions, PeerHeaderoptions } from '../listener-constants';
import RightArrow from '../../../assets/images/left-arrow-symbol.svg';
import { useHistory } from 'react-router-dom';
import { paramsToObjects } from '../../../components/common/create-params';
import HeadBack from '../redirect';
import {
  ListenerAudio,
  useGradeAudioMutation,
  useListenerAudioByListenerId,
  useUpdateAudioMutation,
} from '../../../hooks/useListenerAudio';
import { Tag, TagExtended, useTags } from '../../../hooks/useTags';
import {
  defaultErrorMessages,
  defaultExperienceData,
  defaultUnsavedChanges,
  ExperienceDetails,
  ExperiencePayloadData,
  ExperienceTile,
  UnsavedChanges,
} from '../../../components/experience-review/experience-review';
import { toast } from 'react-toastify';
import RouteLeavingGuard from '../../../components/route-leaving-prompt';
import { ROUTE_PATH } from '../../../routes/paths';
import { CreateVideoModal } from './create-video-modal';

interface PeerExperienceReviewProps {
  experienceType: 'audio' | 'video';
}

const ProfileExperienceReview: React.FunctionComponent<PeerExperienceReviewProps> = ({
  experienceType,
}): JSX.Element => {
  const history = useHistory();
  const params = paramsToObjects();
  const listenerId = params['listenerRoleId'];
  const listenerName = params['listenerName'];
  const listenerType = params['listenerType'];

  const { data: listenerAudio, isLoading } = useListenerAudioByListenerId(listenerId, {
    experience_type: experienceType,
  });
  const ungradedAudio = listenerAudio?.data.filter((audio: ListenerAudio) => audio.message_quality === 7);
  const gradedAudio = listenerAudio?.data.filter((audio: ListenerAudio) => audio.message_quality !== 7);
  const { data: initialTopicTags } = useTags({ tag_type: 'topic', include_hidden: true });
  const { mutate: gradeAudio } = useGradeAudioMutation();
  const { mutate: updateAudio } = useUpdateAudioMutation();

  const [createVideoModal, setCreateVideoModal] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ message: string; description: string }>(defaultErrorMessages);
  const [experienceData, setExperienceData] = useState<ExperiencePayloadData>(defaultExperienceData);
  const [gradeDropdown, setGradeDropdown] = useState({
    ungraded: false,
    graded: false,
  });
  const [selectedExperience, setSelectedExperience] = useState<ListenerAudio | undefined>(undefined);
  const [redirectPath, setRedirectPath] = useState<string>('');
  const [topicTags, setTopicTags] = useState<TagExtended[]>([]);
  const [unsavedChanges, setUnsavedChanges] = useState<UnsavedChanges>(defaultUnsavedChanges);

  const processTags = (experience: ListenerAudio) => {
    /*This function adds the hasTag property to the topic tags based on the selected experience's existing tags.*/
    if (initialTopicTags && initialTopicTags.length > 0) {
      const transformedTags: TagExtended[] = initialTopicTags.map((item: Tag) => ({
        ...item,
        hasTag: !!experience?.topic_tag_ids?.includes(item.id),
      }));
      setTopicTags(transformedTags);
    }
  };

  const handleSelectedExperience = (experience?: ListenerAudio) => {
    /*
    This function is called when:
    1. The user changes the filters
    2. The user selects an experience from the list
    3. The user invalidates the audio data (e.g. by submitting a grade / experience update)
    It will:
    1. Try to maintain the selected experience if it still exists in the new data.
    2. If the selected experience doesn't exist, it will select the first ungraded experience.
    3. If there are no ungraded experiences, it will select the first experience.
    4. In all scenarios, it will process the tags for the selected experience and set the unsaved changes modal to the appropriate id.
    */
    if (experience) {
      setSelectedExperience(experience);
      processTags(experience);
      setUnsavedChanges((prev) => ({ ...prev, promptId: experience.id }));
      setGradeDropdown(
        experience.message_quality === 7 ? { ungraded: true, graded: false } : { ungraded: false, graded: true },
      );
    } else {
      const ungraded = listenerAudio?.data.find((audio: ListenerAudio) => audio.message_quality === 7);
      const matchingAudio = listenerAudio?.data.find((audio: ListenerAudio) => audio.id === selectedExperience?.id);
      if (selectedExperience && matchingAudio) {
        setSelectedExperience(selectedExperience);
        processTags(selectedExperience);
        setUnsavedChanges((prev) => ({ ...prev, promptId: selectedExperience.id }));
        setGradeDropdown(
          selectedExperience.message_quality === 7
            ? { ungraded: true, graded: false }
            : { ungraded: false, graded: true },
        );
      } else if (listenerAudio) {
        const newExperience = ungraded || listenerAudio.data[0] || undefined;

        setSelectedExperience(newExperience);
        processTags(newExperience);
        setUnsavedChanges((prev) => ({ ...prev, promptId: newExperience?.id }));
        setGradeDropdown(
          newExperience?.message_quality === 7 ? { ungraded: true, graded: false } : { ungraded: false, graded: true },
        );
      }
    }
  };

  const validateExperience = () => {
    if (topicTags?.filter((tag: TagExtended) => tag.hasTag && tag.name !== 'My Story').length <= 0) {
      toast.error('Experiences must have at least 1 topic tag');
      return false;
    } else if (experienceData.excerpt?.trim()?.length > 0) {
      setErrors((prev: any) => ({
        ...prev,
        description: '',
      }));
      return true;
    } else {
      setErrors((prev: any) => ({
        ...prev,
        description: 'Please Enter a description to proceed',
      }));
      return false;
    }
  };

  const submitGrade = () => {
    if (selectedExperience && experienceData.gradeUpdated) {
      gradeAudio({ listenerId: selectedExperience.listener_role_id, audioId: selectedExperience.id, experienceData });
    }
  };

  const submitExperience = () => {
    if (selectedExperience && (experienceData.topicsUpdated || experienceData.gradeUpdated) && validateExperience()) {
      setErrors(defaultErrorMessages);
      updateAudio({
        listenerId: selectedExperience.listener_role_id,
        audioId: selectedExperience.id,
        selectedExperience,
        experienceData,
        topicTags,
      });
    }
  };

  useEffect(() => {
    handleSelectedExperience();
    if (redirectPath?.length > 0) {
      history.push(redirectPath);
      setRedirectPath('');
    }
    setUnsavedChanges(defaultUnsavedChanges);
  }, [listenerAudio, initialTopicTags]);

  return (
    <div className="px-">
      {isLoading && <Spinner />}
      <Header heading={<HeadBack listenerName={listenerName || ''} />} />
      <SwitchBar
        heading={listenerType === 'peer' ? PeerHeaderoptions : Headeroptions}
        position={experienceType === 'audio' ? (listenerType === 'peer' ? 2 : 4) : listenerType === 'peer' ? 3 : 5}
        forwardParams
        optionalButton={
          experienceType === 'video' && (
            <button
              className="rounded-full w-32 h-8 text-center text-md text-white px-2 py-1 stacked-bar-blue-400"
              onClick={() => setCreateVideoModal(true)}
            >
              Create Video
            </button>
          )
        }
      />
      <div className="max-window-height pb-32 pt-4 overflow-y-auto">
        <div className="px-7 flex space-x-4 py-5">
          <div className="w-1/2 ">
            <div
              className="flex justify-between border-b border-gray-500 pb-2 cursor-pointer"
              onClick={() => {
                setGradeDropdown((prev) => ({
                  ...prev,
                  ungraded: !prev.ungraded,
                }));
              }}
            >
              <p className="font-bold text-gray-dark text-left">Ungraded ({ungradedAudio?.length || 0})</p>
              <p className="pr-2 text-xl cursor-pointer">
                <div
                  className={`flex justify-end bg-white transform rounded-full ${gradeDropdown.ungraded ? 'rotate-90 rotate-down' : 'rotate-270 rotate-up'}`}
                >
                  <img className="h-6 w-6" src={RightArrow} alt="Right Arrow"></img>
                </div>
              </p>
            </div>
            <div
              className={`overflow-hidden transition-max-height duration-300 ${gradeDropdown.ungraded ? 'max-h-screen' : 'max-h-0 '}`}
            >
              {ungradedAudio &&
                ungradedAudio.length > 0 &&
                ungradedAudio.map((audio: ListenerAudio) => (
                  <ExperienceTile
                    key={audio.id}
                    experience={audio}
                    selectedExperience={selectedExperience}
                    handleSelectedExperience={handleSelectedExperience}
                    unsavedChanges={unsavedChanges}
                    setUnsavedChanges={setUnsavedChanges}
                    listenerId={listenerId}
                  />
                ))}
            </div>
            <div
              className="flex justify-between border-b border-gray-500 pt-3 pb-2 text-gray-dark"
              onClick={() => {
                setGradeDropdown((prev) => ({
                  ...prev,
                  graded: !prev.graded,
                }));
              }}
            >
              <p className="font-bold  text-left">Graded ({gradedAudio?.length || 0})</p>
              <p className="pr-2 text-xl cursor-pointer">
                <div
                  className={`flex justify-end  bg-white  transform   rounded-full
                    ${gradeDropdown.graded ? 'rotate-90 rotate-down' : 'rotate-270 rotate-up'}`}
                >
                  <img className="h-6 w-6" src={RightArrow} alt="Right Arrow"></img>
                </div>
              </p>
            </div>
            <div
              className={`overflow-hidden transition-max-height duration-300 ${gradeDropdown.graded ? 'max-h-screen' : 'max-h-0 '}`}
            >
              {gradedAudio &&
                gradedAudio.length > 0 &&
                gradedAudio.map((audio: ListenerAudio) => (
                  <ExperienceTile
                    key={audio.id}
                    experience={audio}
                    selectedExperience={selectedExperience}
                    handleSelectedExperience={handleSelectedExperience}
                    unsavedChanges={unsavedChanges}
                    setUnsavedChanges={setUnsavedChanges}
                    listenerId={listenerId}
                  />
                ))}
            </div>
          </div>
          <div className="w-1/2 px-3">
            {selectedExperience && topicTags && topicTags.length > 0 && (
              <ExperienceDetails
                key={selectedExperience.id || 0}
                selectedExperience={selectedExperience}
                experienceData={experienceData}
                setExperienceData={setExperienceData}
                topicTags={topicTags}
                setTags={setTopicTags}
                setIsDirty={(isDirty: boolean) => setUnsavedChanges((prev: UnsavedChanges) => ({ ...prev, isDirty }))}
                setTopicsDirty={(isTopicsDirty: boolean) =>
                  setUnsavedChanges((prev: UnsavedChanges) => ({
                    ...prev,
                    isTopicsDirty,
                  }))
                }
                submitGrade={submitGrade}
                errors={errors}
                isDirty={unsavedChanges.isDirty || unsavedChanges.isTopicsDirty}
                submit={submitExperience}
                experienceType={experienceType}
              />
            )}
          </div>
        </div>
      </div>
      {experienceType === 'video' && (
        <CreateVideoModal
          listenerId={listenerId}
          setCreateVideoModal={setCreateVideoModal}
          visible={createVideoModal}
        />
      )}
      <RouteLeavingGuard
        when={experienceData.gradeUpdated || experienceData.topicsUpdated}
        navigate={(path: string) => {
          history.push(path);
        }}
        shouldBlockNavigation={(location: any) => {
          if (location.pathname !== ROUTE_PATH.LISTENER_AUDIO_REVIEW) {
            return true;
          }
          return false;
        }}
        forceShowModal={unsavedChanges.modalVisible}
        titleText={'Alert'}
        contentText={'You have unsaved changes.  If you leave this screen without saving, your changes will be lost.'}
        cancelButtonText="Cancel"
        confirmSaveButtonText={'Save Changes'}
        confirmButtonText={'Disregard Changes'}
        handleContinueSaveChange={(path: string) => {
          if (validateExperience()) {
            setRedirectPath(path);
            setExperienceData((prev: any) => ({
              ...prev,
              topicsUpdated: false,
              gradeUpdated: false,
            }));
            submitExperience();
            experienceData.gradeUpdated && submitGrade();
          }
        }}
        additionalHandlePrompt={() => {
          const experience = listenerAudio?.data.find((audio: ListenerAudio) => audio.id === unsavedChanges.promptId);
          handleSelectedExperience(experience);
          setExperienceData((prev: any) => ({
            ...prev,
            topicsUpdated: false,
            gradeUpdated: false,
          }));
          setUnsavedChanges(defaultUnsavedChanges);
        }}
      />
    </div>
  );
};

export default ProfileExperienceReview;
