export const ROUTE_PATH = {
  LOGIN: '/',
  DASHBOARD: '/dashboard',
  DASHBOARD_OVERVIEW: '/dashboard/overview',
  DASHBOARD_ENGAGEMENT: '/dashboard/engagement',
  DASHBOARD_TOPICS: '/dashboard/topics',
  DASHBOARD_PEERS: '/dashboard/peers',
  DASHBOARD_NAVIGATION: '/dashboard/navigation',
  LISTENERS: '/listeners',
  SEARCH_QUERIES: '/search-queries',
  SEARCH_QUERIES_TEST: '/search-queries/test',
  LINKS: '/links',
  PLUS_CARE: '/+care',
  PEERS: '/peers',
  PEER_LISTENERS: '/peer-listeners',
  REVIEW: '/review',
  CHECKINS: '/checkins',
  REQUESTS: '/requests',
  DEMOGRAPHICS: '/review',
  PROJECT_MANAGEMENT: '/project-management',
  SAFETY: '/safety',
  LISTENERS_PROFILE: '/listeners/profile',
  LISTENERS_ENGAGEMENT: '/listeners/engagement',
  LISTENERS_CONNECTION: '/listeners/connection',
  LISTENERS_AVAILABLE: '/listeners/availabilty',
  LISTENER_AUDIO_REVIEW: '/listeners/audio',
  LISTENER_VIDEO_REVIEW: '/listeners/video',
  LISTENER_PAYOUT: '/listeners/payout',
  LISTENERS_LOG: '/listeners/log',
  LISTENERS_ON_CALL: '/listeners/oncall',

  PEERS_PROFILE: '/peers/profile',
  PEERS_ENGAGEMENT: '/peers/engagement',
  PEERS_CONNECTION: '/peers/connection',
  PEER_AUDIO_REVIEW: '/peers/audio',
  PEER_VIDEO_REVIEW: '/peers/video',
  PEER_PAYOUT: '/peers/payout',

  MEMBERS: '/members',
  MEMBERS_PROFILE: '/members/profile',
  MEMBERS_CONNECTIONS: '/members/connection',
  MEMBERS_REWARDS: '/members/rewards',
  MEMBERS_COMMS: '/members/comms',
  MEMBERS_LOG: '/members/log',
  MEMBERS_ENGAGEMENT: '/members/engagement',
  SETTINGS: '/settings',
  STORIES: '/stories',
  TOPICS: '/topics',
  GROUPS: '/groups',
  GROUPS_PACKAGES: '/groups/packages',
  GROUPS_CONFIGURE: '/groups/configure',
  GROUPS_ELIGIBILITY: '/groups/eligibility',
  GROUPS_RESOURCES: '/groups/resources',
  GROUPS_ADMINS: '/groups/admins',
  GROUPS_SSO_CONFIGURATION: '/groups/sso-configuration',
  REPORTING: '/groups/reporting',
  TEMPLATES_COMMS: '/templates/comms',
  TEMPLATES_CAMPAIGNS: '/templates/campaigns',
  NOTIFY_MESSAGE: '/notify-message',
  ONBOARDING: '/onboarding',
  REQUESTNEWINVITE: '/request-new-invite',
  INVITEREVOKE: '/invite-revoked',
  MEMBERSTOC: '/text-block/members',
  CLIENTADMINTOC: '/text-block/client-admin',
  LISTENRFAQTOC: '/text-block/listener-faq',
  INVITATION_COMPLETED: '/onboarding-completed',
  TIERS: '/tiers',
  TIER_DETAILS: '/tier-details',
  TAGS: '/tags',
  TAGS_DETAILS: '/tags-details',
  TAG_GROUPS: '/tag-groups',
  TAG_GROUPS_DETAILS: '/tag-groups-details',
  RESOURCES: '/resources',
  RESOURCES_DETAILS: '/resources-details',
  AUDIO_REVIEW: '/listeners-reviews/audio',
  VIDEO_REVIEW: '/listeners-reviews/video',
  PROFILE: '/listeners-reviews/profiles',
  DISPLAY_NAME: '/listeners-reviews/display-name',
  ABOUT_ME: '/listeners-reviews/about-me',
  BACKGROUND_TAGS: '/listeners-reviews/background-tags',
  FILES: '/files',
  CALLS: '/calls',
  SYSTEM_USERS: '/users',

  //clients
  SNAPSHOT: '/snapshot',
  ENAGEMENT: '/engagement',
  ELIGIBILTY: '/eligibility',
  GROUPMANAGER: '/group-manager',
  MEMBER_RESOURCES: '/member-resources',
  MARKETING_LIBRARY: '/marketing-library',
  USER_MANAGEMENT: '/user-safety/user-management',
  BLACKLIST: '/user-safety/blacklist',
  BLOCKED: '/user-safety/blocked',
};
