import React, { useEffect, useState, useCallback } from 'react';
import Header from '../../components/common/global-top-bar';

import { connect, useDispatch } from 'react-redux';
import {
  fetchListenerCountRequest,
  fetchListenersRequest,
  updateListenersProfileRejectedRequest,
} from './listeners-redux/actions';
import Spinner from '../../components/common/spinner';
import { Pagination } from '../../components/common';
import Searchbar from '../../components/common/searchbar';
import { listenerTypes } from '../peer-profiles/listener-constants';
import SwitchBar from '../../components/switch-bar';
import { HeaderOptions } from './common/listener-review-constants';
import { fetchCommsListRequest } from '../templates/redux-templates/actions';
import ProfileGradeCard from '../peer-profiles/common/profile-grade-card';

type props = {
  listeners: any;
  listenersCount: any;
  comms: any;
  pending: boolean;
};

type FilterProps = {
  count: number | undefined;
  fetchData: (data: any) => void;
};

const ProfileStatusOptions = [
  { label: 'Needs Review', value: 'review' },
  { label: 'Approve', value: 'approve' },
  { label: 'Reject', value: 'reject' },
];

const Filters: React.FunctionComponent<FilterProps> = ({ count, fetchData }): JSX.Element => {
  const [filters, setfilters] = useState({
    page: 1,
    limit: 25,
    search: '',
    type: 'All',
    profile_photo_approved: false,
    hide_incomplete_peers: true,
  });

  useEffect(() => {
    fetchData(filters);
  }, [filters, fetchData]);

  return (
    <div className="w-full border-b gray-border-line flex justify-between items-center bg-gray-background-light px-7  h-10 ">
      <div className="w-1/4">
        <div className="w-full h-8">
          <Searchbar
            search={(data) => {
              setfilters((prev) => {
                return { ...prev, page: 1, search: data };
              });
            }}
          />
        </div>
      </div>
      <div className="w-1/3">
        <Pagination
          pageCount={Math.ceil((count || 10) / 25)}
          key={Math.ceil((count || 10) / 25)}
          updatePageNumber={(pages) =>
            setfilters((filter) => {
              return { ...filter, page: pages + 1 };
            })
          }
        />
      </div>
      <div className="w-1/3">
        <div className=" flex justify-end">
          <div className="flex justify-between items-center py-4  gray-background-dark">
            {Object.keys(listenerTypes).map((item) => (
              <button
                className={
                  ' text-sm  px-5 py-1 ' +
                  //@ts-ignore
                  (listenerTypes[item] === filters.type ||
                  //@ts-ignore
                  listenerTypes[item] === filters.user_type
                    ? 'bg-blue-primary text-white'
                    : 'bg-gray-background-dark text-gray-dark')
                }
                onClick={() => {
                  setfilters((filter: any) => {
                    return item === 'Peer Listeners' || item === 'Peers'
                      ? {
                          ...filter,
                          user_type: listenerTypes[item],
                          page: 1,
                          type: undefined,
                        }
                      : item === 'Active'
                        ? {
                            ...filter,
                            type: listenerTypes[item],
                            page: 1,
                            user_type: listenerTypes[item],
                          }
                        : {
                            ...filter,
                            type: 'All',
                            page: 1,
                            user_type: undefined,
                          };
                  });
                }}
              >
                {/* @ts-ignore */}
                {item}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const ProfileReview: React.FunctionComponent<props> = (props): JSX.Element => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState<any>({
    page: 1,
    limit: 25,
    search: '',
    type: 'All',
  });

  const fetchData = useCallback(
    (filter: any) => {
      if (filter.type === 'active_peers') {
        //@ts-ignore
        filter = { ...filter, active_peers: true };
      }
      setFilters(filter);
      // 'type' is not a query param supported by backend, so delete it from payload before sending
      const filterCopy = { ...filter };
      delete filterCopy.type;
      dispatch(fetchListenersRequest(filterCopy));
      dispatch(fetchListenerCountRequest(filterCopy));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(fetchCommsListRequest({ only_group_key: 'listener_profile_rejected' }));
  }, [dispatch]);

  return (
    <div>
      {props.pending && <Spinner />}
      <Header heading={'Avatars'} />
      <SwitchBar heading={HeaderOptions} position={2} />
      <Filters fetchData={fetchData} count={props.listenersCount?.count} />

      <div className="max-window-height  overflow-y-auto  px-7 ">
        {props.listeners?.length > 0 &&
          props.listeners.map((ListenerDetails: any) => (
            <ProfileGradeCard
              key={ListenerDetails?.id}
              currentPhotoUrl={ListenerDetails?.current_profile_photo?.file_url}
              proposedPhotoUrl={ListenerDetails?.proposed_profile_photo?.file_url}
              profilePicVerified={!!ListenerDetails?.current_profile_photo}
              lastName={ListenerDetails?.user?.last_name || ''}
              firstName={ListenerDetails?.user?.first_name || ''}
              displayName={ListenerDetails?.user?.display_name || ''}
              comms={props.comms}
              peerState={ListenerDetails?.state}
              email={ListenerDetails?.user.email_address}
              userId={ListenerDetails?.user.id}
              listenerId={ListenerDetails?.id}
              filters={filters}
              switchboardArea="review"
            />
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    listeners: state.Listeners?.listeners?.data || null,
    listenersCount: state.Listeners?.listenersCount?.data || null,
    comms: state.Comms.CommsList?.data || null,
    pending: state.Listeners?.pending || false,
  };
};

export default connect(mapStateToProps)(ProfileReview);
