type props = {
  onSave?: any;
  disable?: boolean;
};

const SwitchBarButton: React.FC<props> = ({ onSave, disable }): JSX.Element => (
  <div className="w-full">
    <button
      className={`rounded-full w-full text-center h-6 text-sm text-white px-2 ${
        disable ? 'pointer-events-none cursor-not-allowed bg-gray-300' : 'stacked-bar-blue-400'
      }`}
      onClick={onSave}
      disabled={disable}
    >
      SAVE
    </button>
  </div>
);

export default SwitchBarButton;
