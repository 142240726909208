import { useQuery } from '@tanstack/react-query';
import { axiosGet } from '../api/axios';

export interface Tag {
  id: number;
  is_background: boolean;
  is_default: boolean;
  is_required: boolean;
  is_visible: boolean;
  media: any;
  name: string;
  sort_order: number;
  tag_type: string;
}

export interface TagExtended extends Tag {
  hasTag?: boolean;
}

export interface TagRequestParams {
  tag_type: string;
  include_hidden?: boolean;
}

const getTags = async (params: TagRequestParams): Promise<Tag[]> => {
  return await axiosGet('/tags/', { ...params }, 'v2').then((tagsResponse) => tagsResponse.data);
};

export const useTags = (params: TagRequestParams) => {
  const { data, isLoading, error } = useQuery<Tag[]>(['tags', params], () => getTags(params));

  return { data, isLoading, error };
};
