import SwitchBar from '../../components/switch-bar';
import { useClient, useUpdateClient } from '../../hooks/useClients';
import { HeaderOptions } from './clients-constants';
import HeadBack from './redirect';
import Header from '../../components/common/global-top-bar';
import { useState } from 'react';
import { Button, Dialog, Input } from '@kindlyhuman/component-library';
import { toast } from 'react-toastify';

export const SSOConfiguration = () => {
  const [settingsModal, setSettingsModal] = useState(false);
  const clientId = +localStorage.getItem('clientid')!;
  const { data: client } = useClient(clientId);
  const { updateClient } = useUpdateClient(clientId);

  const clearSSOConfiguration = () => {
    updateClient.mutate(
      { sso_configuration: null },
      {
        onSuccess: () => {
          setSettingsModal(false);
        },
      },
    );
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget as HTMLFormElement;
    const formData = new FormData(form);
    const auth_url_value: String = (formData.get('authentication_url') as string).trim();
    if (auth_url_value === '') {
      toast.error('Enter an SSO authentication url or clear the SSO configuration to continue.');
      return;
    }
    const sso_configuration = {
      authentication_url: auth_url_value,
    };
    updateClient.mutate(
      { sso_configuration: { ...client?.sso_configuration, ...sso_configuration } },
      {
        onSuccess: () => {
          setSettingsModal(false);
        },
      },
    );
  };

  return (
    <div key={client?.id}>
      <Header heading={<HeadBack clientName={client?.name || ''} />} />
      <SwitchBar heading={HeaderOptions} position={6} />
      <div className="p-8">
        <div>
          {client?.sso_configuration && (
            <div className="p-3 w-2/3">
              Client is configured for SSO.
              <br />
              Members who onboard to this client will not be able to enter a password during onboarding or authenticate
              with our system unless the client supports SSO.
              <br />
              Click edit to clear the SSO configuration or edit the authentication URL.
            </div>
          )}
          {client?.sso_configuration
            ? Object.entries(client.sso_configuration).map(([key, value]) => (
                <div className="p-3" key={key}>
                  {`${key}: ${value}`}
                </div>
              ))
            : 'No SSO Configuration'}
        </div>
        <Button className="mt-8" onClick={() => setSettingsModal(true)} variant="primary">
          Edit
        </Button>
        <Dialog className="p-4 w-2/3" open={settingsModal} onClose={() => setSettingsModal(false)}>
          <form onSubmit={handleSubmit}>
            <h3>SSO Configuration Options</h3>
            <Input
              key={client?.sso_configuration?.authentication_url}
              id="authentication_url"
              name="authentication_url"
              className="p-8"
              placeholder="SSO authentication url"
              onIconClick={() => {}}
              defaultValue={client?.sso_configuration?.authentication_url ?? undefined}
            />
            <div className="flex justify-between gap-x-4">
              <div>
                <Button
                  className="text-redSalsa border-redSalsa"
                  type="button"
                  onClick={() => clearSSOConfiguration()}
                  variant="secondary"
                >
                  Clear SSO Configuration
                </Button>
              </div>
              <div>
                <Button className="mx-4" type="button" onClick={() => setSettingsModal(false)} variant="secondary">
                  Cancel
                </Button>
                <Button type="submit" variant="primary">
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </Dialog>
      </div>
    </div>
  );
};
