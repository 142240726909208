import { ROUTE_PATH } from './paths';
import Stories from '../containers/stories';
import EligibilityFile from '../containers/client-configuration/eligibilty-file';
import ClientsLanding from '../containers/client-configuration/index';
import ClientEligibility from '../containers/client-configuration/eligibility';
import Onboarding from '../containers/onboarding';
import { Redirect, Route, Switch } from 'react-router';
import PrivateRoute from './privateRoute';
import { publicRoutes } from './public-router';

export const clientAdminRoutes = [
  // maybe these?
  {
    path: ROUTE_PATH.SNAPSHOT,
    component: ClientsLanding,
    id: 'Snapshot',
  },
  {
    path: ROUTE_PATH.ELIGIBILTY,
    component: EligibilityFile,
    id: 'Eligibility',
  },
  {
    path: ROUTE_PATH.GROUPMANAGER,
    component: ClientEligibility,
    id: 'Group Manager',
  },
  {
    path: ROUTE_PATH.MEMBER_RESOURCES,
    component: Stories,
    id: 'Group Manager',
  },
  {
    path: ROUTE_PATH.MARKETING_LIBRARY,
    component: Stories,
    id: 'Group Manager',
  },
];

const clientAdminOnboardinRoutes = [
  {
    path: ROUTE_PATH.ONBOARDING,
    component: Onboarding,
    id: 'Group Manager',
  },
];

export const ClientOnboardingRouter = () => {
  return (
    <Switch>
      {clientAdminOnboardinRoutes.map((route) => (
        <Route exact component={route.component} path={route.path} />
      ))}
      {publicRoutes.map((route) => (
        <Route path={route.path} component={route.component} />
      ))}
      <Route exact path="*" render={() => <Redirect to={ROUTE_PATH.ONBOARDING} />} />
    </Switch>
  );
};

export const ClientAdminRouter = () => {
  return (
    <Switch>
      {clientAdminRoutes.map((route) => (
        <PrivateRoute exact selectedId={route.id} component={route.component} path={route.path} />
      ))}
      {publicRoutes.map((route) => (
        <Route path={route.path} component={route.component} />
      ))}
      <Route exact path="*" render={() => <Redirect to={ROUTE_PATH.SNAPSHOT} />} />
    </Switch>
  );
};
