import React from 'react';
import { useHistory } from 'react-router-dom';

type props = {
  heading: header[];
  position: number;
  optionalButton?: React.ReactNode;
  isDisabled?: any;
  isDataModified?: any;
  forwardParams?: boolean;
  setPosition?: any;
};

interface header {
  path: string;
  heading: string;
}

const SwitchBar: React.FunctionComponent<props> = ({
  heading,
  position,
  optionalButton,
  isDisabled,
  forwardParams = false,
  setPosition = undefined,
}): JSX.Element => {
  const history = useHistory();

  return (
    <div className="w-full border-b gray-border-line flex justify-between items-center bg-gray-background-light px-4  h-10 ">
      <div className="flex justify-center items-center   gray-background-dark h-full">
        {heading.map((item: any, index) => (
          <button
            className={`
              text-sm px-5 py-2
              ${index === position ? 'text-white font-bold bg-blue-primary h-full' : 'text-gray-dark h-full'}
              ${isDisabled ? 'cursor-not-allowed' : ''}
            `}
            onClick={() => {
              history.push(forwardParams ? item.path.toString() + '/' + window.location.search : item.path.toString());
              setPosition && setPosition(index);
            }}
            disabled={isDisabled}
          >
            {item.heading}
          </button>
        ))}
      </div>
      {optionalButton}
    </div>
  );
};

export default SwitchBar;
