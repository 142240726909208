import React, { useEffect, useState } from 'react';
import { DropDown, NumericLabel, SectionLabel } from '../../components/common';
import AudioPlayer from '../../components/common/audio-player-slider';
import SaveButton from '../../containers/clients/switch-bar-button';

import { Dialog } from '@kindlyhuman/component-library';
import { ListenerAudio } from '../../hooks/useListenerAudio';
import { SystemMessage, useSystemMessages } from '../../hooks/useComms';
import { Tag, TagExtended } from '../../hooks/useTags';

export const audioGrades = {
  All: 'all',
  Ungraded: 'ungraded',
  Approved: 'approved',
  Rejected: 'rejected',
};

export interface UnsavedChanges {
  modalVisible: boolean;
  isDirty: boolean;
  isTopicsDirty: boolean;
  promptId?: number;
}

export const defaultErrorMessages = {
  message: '',
  description: '',
};

export interface ExperiencePayloadData {
  grade: string;
  systemMessageGroup: string;
  messageId: string | number;
  message: string;
  excerpt: string;
  admin_summary: string;
  video_id?: string;
  transcription: string;
  topicsUpdated: boolean;
  gradeUpdated: boolean;
}

export const defaultExperienceData = {
  grade: 'rejected',
  systemMessageGroup: 'listener_message_rejected',
  messageId: 'none',
  message: '',
  excerpt: '',
  admin_summary: '',
  transcription: '',
  video_id: '',
  topicsUpdated: false,
  gradeUpdated: false,
};

export const defaultUnsavedChanges = {
  modalVisible: false,
  isDirty: false,
  isTopicsDirty: false,
};

export interface ExperienceTileProps {
  experience: ListenerAudio;
  handleSelectedExperience: (experience?: ListenerAudio) => void;
  listenerId?: number;
  selectedExperience: ListenerAudio | undefined;
  setUnsavedChanges: (value: React.SetStateAction<UnsavedChanges>) => void;
  unsavedChanges: UnsavedChanges;
}

export const ExperienceTile: React.FC<ExperienceTileProps> = ({
  experience: experience,
  handleSelectedExperience,
  listenerId,
  selectedExperience,
  setUnsavedChanges,
  unsavedChanges,
}) => {
  return (
    <div
      data-testid={`experience-${experience.id}`}
      key={experience.id}
      className={` ${
        selectedExperience?.id === experience.id ? 'bg-blue-primary  text-white' : 'bg-gray-200 text-gray-dark'
      } flex justify-between px-7 h-36 items-start py-4 rounded-2xl my-3 cursor-pointer`}
      onClick={() => {
        unsavedChanges.isDirty
          ? setUnsavedChanges((prev: UnsavedChanges) => ({
              ...prev,
              modalVisible: true,
              promptId: experience.id,
            }))
          : handleSelectedExperience(experience);
      }}
    >
      <span>
        <div className="truncate flex flex-col space-y-1 w-90">
          <span>
            <p className="text-neutral-600 text-xxs uppercase">Title</p>
            <p className="font-semibold">{experience.title ? experience.title : <i>No experience title</i>}</p>
          </span>
          {!listenerId && (
            // Right now, v2/listener/id/audio doesn't return the following data, so exclude on that page
            <>
              <span>
                <p className="text-neutral-600 text-xxs uppercase">Listener #{experience.listener_role_id}</p>
                <p className="font-semibold">
                  {experience.listener_role?.user?.first_name || experience?.listener_role?.user?.last_name
                    ? `${experience.listener_role?.user?.first_name} ${experience.listener_role.user.last_name}`
                    : 'No name'}
                </p>
              </span>
              <span>
                <p className="text-neutral-600 text-xxs uppercase">Email</p>
                <p className="font-semibold">{experience.listener_role?.user?.email_address || ''}</p>
              </span>
            </>
          )}
        </div>
      </span>
      <span className="flex flex-col h-full justify-between">
        <span>
          <p className="text-neutral-600 text-xxs uppercase">Audio</p>
          <p className="font-semibold">#{experience.id}</p>
        </span>
        <button
          className={
            'border rounded-md px-4 text-base py-1 font-bold ' +
            (selectedExperience?.id === experience.id
              ? ' border-white '
              : experience?.message_quality === 7
                ? ' border-gray-dark '
                : '') +
            (experience?.message_quality === 3
              ? 'bg-green text-white'
              : experience?.message_quality === 7
                ? ' bg-transparent '
                : ' bg-red text-white')
          }
        >
          {experience?.message_quality === 3 ? 'Approved' : experience?.message_quality === 7 ? 'Ungraded' : 'Rejected'}
        </button>
      </span>
    </div>
  );
};

export interface ExperienceDetailProps {
  selectedExperience: ListenerAudio;
  setIsDirty: (isDirty: boolean) => void;
  setTopicsDirty: (isDirty: boolean) => void;
  experienceData: ExperiencePayloadData;
  setExperienceData: React.Dispatch<React.SetStateAction<any>>;
  submitGrade: () => void;
  errors: any;
  topicTags: TagExtended[];
  setTags: React.Dispatch<React.SetStateAction<TagExtended[]>>;
  isDirty: boolean;
  submit: () => void;
  experienceType: 'audio' | 'video';
}

export const ExperienceDetails: React.FC<ExperienceDetailProps> = ({
  selectedExperience: experience,
  setIsDirty,
  setTopicsDirty,
  experienceData,
  setExperienceData,
  submitGrade,
  errors,
  topicTags,
  setTags,
  isDirty,
  submit,
  experienceType,
}) => {
  const { data: systemMessages } = useSystemMessages();
  const [tagsModalOpen, setTagsModalOpen] = useState(false);
  const experienceRejectedMessages = systemMessages?.filter(
    (item: SystemMessage) =>
      item.group?.key === 'listener_message_rejected' || item.group?.key === 'listener_message_poor',
  );

  const mapAudioToExperienceData = (experience: ListenerAudio) => {
    setExperienceData({
      grade:
        experience?.message_quality === 3 ? 'approved' : experience?.message_quality === 7 ? 'ungraded' : 'rejected',
      systemMessageGroup: 'listener_message_rejected',
      messageId: 'none',
      message: '',
      excerpt: experience?.excerpt || '',
      admin_summary: experience?.admin_summary || '',
      ai_summary: experience?.ai_summary || '',
      transcription: experience?.transcription || '',
      video_id: experience?.video_id || '',
      topicsUpdated: false,
      gradeUpdated: false,
    });
  };

  const handleIsDirty = (isTopics = false) => {
    const type = isTopics ? 'topicsUpdated' : 'gradeUpdated';
    if (!experienceData[type]) {
      setExperienceData((prev: any) => ({
        ...prev,
        [type]: true,
      }));
      if (isTopics) {
        setTopicsDirty(true);
        !experienceData.topicsUpdated && setExperienceData((prev: any) => ({ ...prev, topicsUpdated: true }));
      }
    }
    setIsDirty(true);
  };

  const toggleHasTag = (hasTag: boolean, id: number) => {
    if (!experienceData.topicsUpdated) {
      setExperienceData((prev: any) => ({ ...prev, topicsUpdated: true }));
    }
    const updatedTags = topicTags.map((item: TagExtended) => {
      if (item.id === id) {
        return { ...item, hasTag };
      } else {
        return item;
      }
    });
    setTags(updatedTags);
    handleIsDirty(true);
  };

  useEffect(() => {
    mapAudioToExperienceData(experience);
  }, [experience]);

  return (
    <>
      <div className="border-2 border-gray-200 rounded-3xl px-5 h-auto">
        <span className="w-5/6 inline-flex">
          <span className="font-bold text-left text-gray-dark py-4 text-xl">
            <p className={`font-bold text-left`}>{experience.title ? experience.title : <i>No experience title</i>}</p>
            <p className={`font-bold text-left truncate `}>by {experience.listener_role?.user?.first_name || ''}</p>
            <p className={`font-bold text-left`}>
              aka{' '}
              {experience.listener_role?.user?.display_name
                ? experience.listener_role?.user?.display_name
                : experience.listener_role?.user?.email_address}
            </p>
          </span>
        </span>
        <span className="w-1/6 h-max inline-flex flex-col items-center gap-2 font-bold text-gray-dark py-4">
          <SaveButton
            data-testid="save-button"
            disable={!isDirty}
            onSave={() => {
              submit();
            }}
          />
          <p>#{experience.id}</p>
        </span>
        {experienceType === 'audio' ? (
          <>
            <AudioPlayer audioLength={experience.duration} audioUrl={experience.file_url} />
          </>
        ) : (
          <div className="space-y-4">
            <a
              data-testid="vimeo-url"
              href={`https://vimeo.com/${experienceData.video_id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline hover:text-blue-700"
            >
              Watch on Vimeo
            </a>
            <div className="flex gap-2 items-center">
              <label className="w-24">Vimeo ID</label>
              <input
                data-testid="vimeo-id"
                type="text"
                className="w-full rounded-md text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none"
                value={experienceData.video_id}
                onChange={(event) => {
                  handleIsDirty();
                  setExperienceData((prev: any) => ({
                    ...prev,
                    video_id: event.target.value,
                  }));
                }}
              />
            </div>
          </div>
        )}
        <div className="flex justify-start items-center mt-5">
          <label className="mr-2">Grade</label>
          <div className="w-48">
            <DropDown
              data-testid="grade-dropdown"
              Label={''}
              value={experienceData.grade}
              setValue={(value) => {
                value !== 'ungraded' && handleIsDirty();
                setExperienceData((prev: any) => ({
                  ...prev,
                  grade: value,
                  sytemMessageGroup: value === 'rejected' ? 'listener_message_rejected' : value,
                  gradeUpdated: value === 'ungraded' ? false : true,
                }));
              }}
            >
              {experience?.message_quality === 7 && <option value={'ungraded'}>Ungraded</option>}
              <option value="rejected">Rejected</option>
              <option value="approved">Approved</option>
            </DropDown>
          </div>
        </div>
        {experienceData.grade === 'rejected' && (
          <div className="flex justify-start items-center w-full">
            <label className="mr-2">Message to Listener</label>
            <DropDown
              data-testid="message-dropdown"
              Label={''}
              value={experienceData.messageId}
              setValue={(value) => {
                handleIsDirty();
                const comm = experienceRejectedMessages?.find((item: any) => item.id === Number(value));
                setExperienceData((prev: any) => ({
                  ...prev,
                  messageId: value,
                  message: comm ? comm.message : prev.message,
                }));
              }}
            >
              <option value={'none'}>Do NOT Message Listener</option>
              {experienceRejectedMessages?.map((item: SystemMessage) => <option value={item.id}>{item.name}</option>)}
            </DropDown>
          </div>
        )}
        {experienceData.grade === 'rejected' && (
          <div className="w-full  my-2">
            <p className="mb-2"> Message </p>
            <textarea
              data-testid="rejected-message"
              className="py-2 px-3 w-full rounded-md  h-36 text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none"
              value={experienceData?.message}
              onChange={(evt) => {
                setExperienceData((prev: any) => ({
                  ...prev,
                  message: evt.target.value,
                }));
              }}
            />
            {errors.message?.length > 0 && <p className="text-red">{errors.message}</p>}
          </div>
        )}
        <button
          data-testid="submit-grade"
          className={
            ' py-2 px-3 rounded-full mt-4 ' +
            (experienceData.gradeUpdated
              ? 'bg-blue-primary  text-white'
              : 'bg-gray-background-light text-gray-dark cursor-not-allowed')
          }
          disabled={!experienceData.gradeUpdated || experienceData.grade === '7'}
          onClick={submitGrade}
        >
          Submit Grade
        </button>
        <div>
          <SectionLabel Label={'Description'} />
          <div className="pt-4">
            <textarea
              data-testid="description"
              className="py-2 px-3 w-full rounded-md  h-36 text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
              value={experienceData?.excerpt}
              onChange={(event) => {
                handleIsDirty(true);
                setExperienceData((prev: any) => ({
                  ...prev,
                  excerpt: event.target.value,
                }));
              }}
            />
            <div className="text-right text-sm text-gray-dark">{experienceData?.excerpt?.length} characters</div>
            {errors.description?.length > 0 && <p className="text-red">{errors.description}</p>}
          </div>
        </div>
        <div>
          <SectionLabel Label="Transcription" />
          <div className="pt-4">
            <textarea
              data-testid="transcription"
              className="py-2 px-3 w-full rounded-md  h-36 text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
              value={experienceData?.transcription}
              onChange={(event) => {
                handleIsDirty(true);
                setExperienceData((prev: any) => ({
                  ...prev,
                  transcription: event.target.value,
                }));
              }}
            />
            <div className="text-right text-sm text-gray-dark">{experienceData?.transcription?.length} characters</div>
          </div>
        </div>
        <div>
          <SectionLabel Label={'Admin Summary'} />
          <div className="pt-4">
            <textarea
              data-testid="admin-summary"
              className="py-2 px-3 w-full rounded-md  h-36 text-gray-dark bg-gray-background-light text-sm font-extrabold leading-4 focus:outline-none "
              value={experienceData?.admin_summary}
              onChange={(evt) => {
                handleIsDirty(true);
                setExperienceData((prev: any) => ({
                  ...prev,
                  admin_summary: evt.target.value,
                }));
              }}
            />
            <div className="text-right text-sm text-gray-dark">{experienceData?.admin_summary?.length} characters</div>
          </div>
        </div>
        <div>
          <SectionLabel Label="AI Summary" />
          <div className="pt-2 pb-6">
            {experience.ai_summary ? experience.ai_summary : 'No AI summary generated at this time.'}
          </div>
        </div>
        <div className="flex justify-between">
          <p className="py-2 font-bold text-gray-dark text-left">Topic Tags</p>
          <button
            data-testid="add-tag"
            className="py-2 font-bold text-right text-blue-primary "
            onClick={() => {
              setTagsModalOpen(true);
            }}
          >
            + Add
          </button>
        </div>
        <hr className="bg-gray-dark mb-2"></hr>
        <div className="w-full flex-wrap pb-4 space-x-2 space-y-2 text-sm -ml-2 ">
          {topicTags?.length > 0 &&
            topicTags
              .filter((tag: TagExtended) => tag.hasTag && !tag.is_required)
              .map((tag: TagExtended, index: number) => {
                return (
                  <button
                    data-testid={`tag-${tag.id}`}
                    className={`bg-blue-primary text-white rounded-md text-sm font-bold ${
                      index === 0 ? 'ml-2 mt-2' : ''
                    }`}
                  >
                    <div className="flex px-3 py-2">
                      <div className="grow  pr-2">{tag.name}</div>
                      <div
                        data-testid={`remove-tag-${tag.id}`}
                        className="grow-0 "
                        onClick={() => {
                          toggleHasTag(false, tag.id);
                        }}
                      >
                        x
                      </div>
                    </div>
                  </button>
                );
              })}
        </div>
        {experience?.opensearch_tag_ids && (
          <>
            <hr className="mt-4" />
            <div className="flex flex-col">
              <p className="py-2 font-bold text-gray-dark text-left">AI Recommended Tags</p>
              <div className="flex gap-4 py-4">
                {experience?.opensearch_tag_ids?.map((id: number) => {
                  return (
                    <div className="bg-gray-600 text-white rounded-md text-sm font-bold px-3 py-2">
                      {topicTags.find((tag: Tag) => tag.id === id)?.name}
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
        <div className="flex justify-start mt-2">
          <div className="h-36 w-1/4">
            <SectionLabel Label={'Plays'} />
            <NumericLabel numericValue={experience?.total_playbacks?.toString() || '0'} label={''} />
          </div>
          <div className="h-36 w-1/4">
            <SectionLabel Label={'Favorites'} />
            <NumericLabel numericValue={experience?.total_favorites?.toString() || '0'} label={''} />
          </div>
        </div>
      </div>
      <Dialog open={tagsModalOpen} onClose={() => setTagsModalOpen(false)}>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all ">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6  text-gray-900 font-bold mb-3" id="modal-title">
                  Add Listener Tags
                </h3>
                <div className="w-full pb-4 flex-wrap space-x-2 space-y-2 text-sm -ml-2 ">
                  {topicTags?.length > 0 &&
                    topicTags
                      .filter((tag: TagExtended) => !tag.is_required)
                      .sort((tag1: TagExtended, tag2: TagExtended) => tag1.name.localeCompare(tag2.name))
                      .map((tag: TagExtended, index: number) => {
                        return (
                          <button
                            data-testid={`add-tag-${tag.id}`}
                            className={` ${
                              tag.hasTag ? ' bg-blue-primary  text-white' : ' bg-gray-background-light '
                            }  rounded-md text-sm font-bold ${index === 0 ? ' ml-2 mt-2 ' : ' '}`}
                            onClick={() => {
                              toggleHasTag(!tag.hasTag, tag.id);
                            }}
                          >
                            <div className="flex px-3 py-2">
                              <div className="grow  pr-2">{tag.name}</div>
                            </div>
                          </button>
                        );
                      })}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:justify-center">
            <button
              data-testid="close-tags-modal"
              type="button"
              className={'px-5 py-1 text-white rounded-full font-medium bg-gray-400 '}
              onClick={() => {
                setTagsModalOpen(false);
              }}
            >
              Close
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
