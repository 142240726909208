import { useQuery } from '@tanstack/react-query';
import { axiosGet } from '../api/axios';

export interface MorphiSettings {
  delighted: Object;
  disappointed: Object;
  id: string;
}

export interface AppSettings {
  LOC_LISTENER_BACKGROUND_CHECK_FEE: number;
  LOC_LISTENER_BACKGROUND_CHECK_REWARD: number;
  LOC_CALL_CENTS_PER_MINUTE: number;
  LOC_LISTENER_DISCOVERY_MAX_DISPLAY: number;
  TERMS_OF_SERVICE: string;
  CLIENT_ADMIN_TERMS_OF_SERVICE: string;
  PRIVACY_POLICY: string;
  ABOUT_LOC: string;
  LISTENER_FAQ: string;
  PEER_FAQ: string;
  LOC_MINIMUM_LISTENER_EXPERIENCES: number;
  LOC_MINIMUM_PEER_EXPERIENCES: number;
  timezone_offsets: TimezoneOffsets;
  supported_timezones: string[];
  transformed_timezones: { [key: string]: string };
  LOC_DEFAULT_CLIENT_LOGO: string;
  supported_pronouns: SupportedPronouns;
  supported_races: SupportedRaces;
  DEFAULT_MORPHII_METADATA: DefaultMorphiiMetadata;
  MEMBER_SAMPLE_MESSAGE: string;
}

export interface TimezoneOffsets {
  'US/Eastern': string;
  'US/Central': string;
  'US/Mountain': string;
  'US/Pacific': string;
  'US/Arizona': string;
  'US/Alaska': string;
  'US/Hawaii': string;
  'US/Samoa': string;
  'America/Puerto_Rico': string;
  'Pacific/Guam': string;
}

export interface SupportedPronouns {
  he_him: string;
  she_her: string;
  they_them: string;
  one_one: string;
  ey_em: string;
  per_per: string;
  ve_ver: string;
  xe_xem: string;
  ze_zir: string;
  other: string;
}

export interface SupportedRaces {
  first_nations: string;
  asian: string;
  black: string;
  latino: string;
  pacific_islander: string;
  white: string;
  multiple: string;
  other: string;
}

export interface DefaultMorphiiMetadata {
  id: string;
  label: string;
  delighted: Delighted;
  disappointed: Disappointed;
}

export interface Delighted {
  polarity: number;
}

export interface Disappointed {
  polarity: number;
}

export interface StateOption {
  label: string;
  value: string;
}

const getAppSettings = async (): Promise<AppSettings> => {
  return await axiosGet(`/settings/app`, {}).then((appResponse) => appResponse.data.data.app);
};

export const useAppSettings = () =>
  useQuery<AppSettings>(['appSettings'], () => getAppSettings(), {
    staleTime: Infinity,
    select: (appSettings) => {
      const transformed_timezones = appSettings.supported_timezones.reduce(
        (acc: { [key: string]: string }, timezone: string) => {
          if (timezone === 'Pacific/Guam') {
            acc['US/Guam'] = timezone;
          } else if (timezone === 'America/Puerto_Rico') {
            acc['US/Puerto Rico'] = timezone;
          } else {
            acc[timezone] = timezone;
          }
          return acc;
        },
        {},
      );

      return { ...appSettings, transformed_timezones };
    },
  });

export const useUserStateOptions = () =>
  useQuery<StateOption[]>(['userStateOptions'], () =>
    axiosGet('/users/state_options', {}).then((response) => response.data),
  );
