import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { axiosGet, axiosPost, axiosPut } from '../api/axios';
import { toast } from 'react-toastify';
import { TagExtended } from './useTags';
import { ExperiencePayloadData } from '../components/experience-review/experience-review';

export interface ListenerAudio {
  admin_summary: any;
  ai_summary: any;
  created_at: string;
  duration: number;
  excerpt: string;
  file_url: string;
  id: number;
  listener_role: ListenerRole;
  listener_role_id: number;
  message_quality: number;
  opensearch_tag_ids: number[];
  title: string;
  topic_tag_ids: number[];
  topic_tags: TagToEntity[];
  total_favorites: number;
  total_playbacks: number;
  transcription: string;
  video_id?: string;
}

export interface TagToEntity {
  created_at: string;
  id: number;
  listener_audio_id: number;
  media: any;
  postcall_metadata_id: any;
  resource_id: any;
  sort_order: number;
  status: string;
  tag: Tag;
  tag_id: number;
  user_id: any;
}

export interface Tag {
  id: number;
  is_background: boolean;
  is_default: boolean;
  is_required: boolean;
  is_visible: boolean;
  media: Media[];
  name: string;
  sort_order: number;
  tag_type: string;
}

export interface Media {
  file_key: string;
  file_url: string;
}

export interface ListenerRole {
  id: number;
  user: User;
}

export interface User {
  created_at: string;
  display_name: string;
  display_name_rejected: boolean;
  email_address: string;
  first_name: string;
  id: number;
  last_name: string;
  proposed_display_name: string;
  test_user: boolean;
}

export interface ListenerAudioResponse {
  count: number;
  data: ListenerAudio[];
}

export interface ListenerAudioRequestParams {
  experience_type?: string;
  grade?: string;
  hide_incomplete_peers?: boolean;
  include_test_user?: boolean;
  limit?: number;
  order_by?: string;
  order_by_direction?: string;
  page?: number;
  search?: string;
}

const getAllListenersAudio = async (params: ListenerAudioRequestParams): Promise<ListenerAudioResponse> => {
  return await axiosGet('/listener_audio', { ...params }, 'v3').then(
    (listenerAudioResponse) => listenerAudioResponse.data,
  );
};

const getListenerAudioByListenerId = async (listenerId: number, params: ListenerAudioRequestParams) => {
  return await axiosGet(`/listener_audio/listener/${listenerId}`, { ...params }, 'v3').then(
    (listenerAudioResponse) => listenerAudioResponse.data,
  );
};

export const useAllListenerAudio = (params: ListenerAudioRequestParams) => {
  const { data, isLoading, error, refetch } = useQuery<ListenerAudioResponse>(['listener_audio', params], () =>
    getAllListenersAudio(params),
  );

  return { data, isLoading, error, refetch };
};

export const useListenerAudioByListenerId = (listenerId: number, params: ListenerAudioRequestParams) => {
  const { data, isLoading, error, refetch } = useQuery<ListenerAudioResponse>(
    ['listener_audio', listenerId, params],
    () => getListenerAudioByListenerId(listenerId, params),
  );

  return { data, isLoading, error, refetch };
};

export interface CreateExperienceRequest {
  file_path?: string;
  video_id?: string;
  title?: string;
  excerpt?: string;
  admin_summary?: string;
  message_quality?: string;
  topic_tag_ids?: number[];
}

const createExperience = async (listenerId: number, payload: CreateExperienceRequest) => {
  const response = await axiosPost(`/listeners/${listenerId}/audio`, payload, 'v2');
  return response.data;
};

export const useCreateExperienceMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ listenerId, payload }: { listenerId: number; payload: CreateExperienceRequest }) => {
      return createExperience(listenerId, payload);
    },
    {
      onSuccess: () => {
        toast.success('Experience Created Successfully');
        queryClient.invalidateQueries(['listener_audio']);
      },
      onError: (error: any) => {
        try {
          const parsedError = JSON.parse(error);
          toast.error(parsedError?.data.description);
        } catch (e) {
          toast.error('Unknown error');
        }
      },
    },
  );
};

interface UpdateAudioRequest {
  topic_tag_ids?: number[];
  excerpt?: string;
  admin_summary?: string;
}

const updateAudio = async (listenerId: number, audioId: number, payload: UpdateAudioRequest) => {
  const response = await axiosPut(`/listeners/${listenerId}/audio/${audioId}`, payload, 'v2');
  return response.data;
};

export const useUpdateAudioMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      listenerId,
      audioId,
      selectedExperience,
      experienceData,
      topicTags,
    }: {
      listenerId: number;
      audioId: number;
      selectedExperience: ListenerAudio;
      experienceData: ExperiencePayloadData;
      topicTags: Tag[];
    }) => {
      const selectedIds = topicTags.filter((tag: TagExtended) => tag.hasTag).map((tag: TagExtended) => tag.id);
      const MyStory = topicTags.find((tag: TagExtended) => tag.name === 'My Story' && tag.is_required);

      const payload = {
        topic_tag_ids: selectedExperience.title === 'My Story' && MyStory ? [...selectedIds, MyStory.id] : selectedIds,
        excerpt: experienceData.excerpt !== selectedExperience.excerpt ? experienceData.excerpt.trim() : undefined,
        admin_summary:
          experienceData.admin_summary !== selectedExperience.admin_summary
            ? experienceData.admin_summary.trim()
            : undefined,
        transcription:
          experienceData.transcription !== selectedExperience.transcription
            ? experienceData.transcription.trim()
            : undefined,
        video_id: experienceData.video_id != selectedExperience.video_id ? experienceData.video_id : undefined,
      };

      return updateAudio(listenerId, audioId, payload);
    },
    {
      onSuccess: () => {
        toast.success('Audio Updated Successfully');
        queryClient.invalidateQueries(['listener_audio']);
      },
      onError: (error: any) => {
        try {
          const parsedError = JSON.parse(error);
          toast.error(parsedError?.data.description);
        } catch (e) {
          toast.error('Unknown error');
        }
      },
    },
  );
};

interface GradeAudioRequest {
  system_message_id?: number;
  message?: string;
  message_quality: string;
}

const gradeAudio = async (listenerId: number, audioId: number, payload: GradeAudioRequest) => {
  const response = await axiosPost(`/listeners/${listenerId}/audio/${audioId}/grade`, payload, 'v2');
  return response.data;
};

export const useGradeAudioMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      listenerId,
      audioId,
      experienceData,
    }: {
      listenerId: number;
      audioId: number;
      experienceData: ExperiencePayloadData;
    }) => {
      const sendMessage = !!(experienceData.messageId !== 'none' && experienceData.grade !== 'approved');
      const payload = {
        system_message_id: sendMessage ? +experienceData.messageId : undefined,
        message: sendMessage ? experienceData.message.trim() : undefined,
        message_quality: experienceData.grade,
      };

      return gradeAudio(listenerId, audioId, payload);
    },
    {
      onSuccess: () => {
        toast.success('Audio Grade Submitted Successfully');
        queryClient.invalidateQueries(['listener_audio']);
      },
      onError: (error: any) => {
        try {
          const parsedError = JSON.parse(error);
          toast.error(parsedError?.data.description);
        } catch (e) {
          toast.error('Unknown error');
        }
      },
    },
  );
};
