import * as React from 'react';
import moment from 'moment';
import useAuth from '../../hooks/useAuth';

type GlobalDashBoardProps = {
  heading?: React.ReactNode;
  disableText?: boolean;
};

const GlobalDashboard: React.FunctionComponent<GlobalDashBoardProps> = ({ heading, disableText }): JSX.Element => {
  const { logout, user } = useAuth();
  const username = user?.email_address || user?.first_name || user?.display_name || 'User';
  const todayDate = moment().format('MMM Do YYYY');
  const [currentTime, setCurrentTime] = React.useState(moment().format('hh:mm A'));

  React.useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format('hh:mm A'));
    }, 60000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className=" bg-gray-background-light px-2  border-b gray-border-line h-20">
      <div className="flex justify-between items-center py-2 px-4 gray-background-light h-full">
        <div className={disableText ? ' w-1/2 ' : 'text-xl font-bold blue-primary flex '}>{heading}</div>
        <div className="flex font-medium text-sm justify-between items-center text-gray-dark ">
          <div className="px-3 min-w-44 text-right">
            {todayDate} {currentTime}
          </div>
          <div className="px-3 font-bold"> {username}</div>
          <button className="rounded-full text-xs bg-white px-5 py-1" onClick={() => logout()}>
            LOG OUT
          </button>
        </div>
      </div>
    </div>
  );
};

export default GlobalDashboard;
